import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfileDetails } from '../../services/profiles/action';
import { formatCalendarDate, getOfferTypes, getUserProfileStyles, renderHTML } from '../../../common/common';
import { isLoggedIn } from '../../services/authentication/action';
import { getLabel } from '../../../common/getLabel';
import { baseUrl } from '../../common/config';
import { writeMetaTags } from '../../services/meta-tags/action';
import { useSelector, useDispatch } from "react-redux"
import { convertYouTubeURL, SITE_TITLE } from '../../common/common';

const ProfileDetail = ({ record_id }) => {
    // const { record_id } = useParams();
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const [yearsField, setYearsFields] = useState(null);
    const [profileData, setProfileData] = useState('');
    const [collegeStatus, setCollegeStatus] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const defaultMetaTags = headerFooter?.header?.defaultMeta || [];

    useEffect(() => {
        async function fetchData() {
            if (!record_id) {
                navigate('/qb-profiles');
                return;
            }
            const value = sessionStorage.getItem(`VIEWEDQB${record_id}`);
            const addView = value === null;

            const response = await getProfileDetails({ profile_id: record_id, addView });
            if (response && response.success === true) {
                sessionStorage.setItem(`VIEWEDQB${record_id}`, '1');
                setProfileData(response.data);
            } else {
                navigate('/qb-profiles');
                return;
            }
        }
        fetchData();
    }, [record_id]);

    const profile_image = (profileData && profileData.profile_image) ? profileData.profile_image : '';
    const ogImage = profile_image && profile_image[0] && profile_image[0].afile_physical_path ? `${baseUrl}/${profile_image[0].afile_physical_path}` : "/images/qbh-hit.jpg";
    const awards = (profileData && profileData.awards) ? JSON.parse(profileData.awards) : '';
    const sparq = (profileData && profileData.sparq) ? JSON.parse(profileData.sparq) : '';
    const year_1 = (profileData && profileData.year_1) ? JSON.parse(profileData.year_1) : '';
    const year_2 = (profileData && profileData.year_2) ? JSON.parse(profileData.year_2) : '';
    const year_3 = (profileData && profileData.year_3) ? JSON.parse(profileData.year_3) : '';
    const year_4 = (profileData && profileData.year_4) ? JSON.parse(profileData.year_4) : '';
    const training = (profileData && profileData.training) ? JSON.parse(profileData.training) : '';
    const camps = (profileData && profileData.camps) ? JSON.parse(profileData.camps) : '';
    const sparq_dash = (sparq && sparq.dash) ? sparq.dash : '';
    const sparq_bench = (sparq && sparq.bench) ? sparq.bench : '';
    const sparq_vertical = (sparq && sparq.vertical) ? sparq.vertical : '';
    const sparq_broad = (sparq && sparq.broad) ? sparq.broad : '';
    const sparq_cone = (sparq && sparq.cone) ? sparq.cone : '';
    useEffect(() => {
        if (profileData) {
            const metaTags = { ...defaultMetaTags };
            metaTags.meta_title = `${profileData.uprofile_fname} ${profileData.uprofile_lname} - ${site_title}`;
            metaTags.meta_other_meta_tags = `<meta property="og:image" content="${ogImage}" />`;
            dispatch(writeMetaTags(metaTags));

            if (profileData.scouting_report) {
                setExpandedAccordion('scouting');
            } else if (sparq_dash || sparq_bench || sparq_vertical || sparq_broad || sparq_cone) {
                setExpandedAccordion('sparq');
            } else if (year_1 || year_2 || year_3 || year_4) {
                setExpandedAccordion('year');
            } else if (profileData.offers && profileData.offers.length > 0) {
                setExpandedAccordion('offers');
                const committedOffer = profileData.offers.find(offer => offer.poffer_commit === 1);
                if (committedOffer) {
                    setCollegeStatus(`Committed to ${committedOffer.poffer_college}`);
                }
            } else if (awards && awards.length > 0) {
                setExpandedAccordion('awards');
            } else {
                setExpandedAccordion(null);
            }

            let yearsData = [];
            for (let i = 1; i <= 4; i++) {
                const yearKey = `year_${i}`;
                if (profileData && profileData[yearKey]) {
                    yearsData.push(JSON.parse(profileData[yearKey]));
                }
            }
            if (yearsData && yearsData.length > 0) {
                setYearsFields(yearsData);
            }

            if (profileData.offers && profileData.offers.length > 0) {
                const committedOffer = profileData.offers.find(offer => offer.poffer_commit === 1);
                if (committedOffer) {
                    setCollegeStatus(`Committed to ${committedOffer.poffer_college}`);
                }
            }
        }
    }, [profileData, dispatch]);
    const school = (profileData.school) ? JSON.parse(profileData.school) : '';
    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const height_feet = (profileData.height_feet) ? JSON.parse(profileData.height_feet) : '';
    const height_inch = (profileData.height_inch) ? JSON.parse(profileData.height_inch) : '';
    const weight = (profileData.weight) ? JSON.parse(profileData.weight) : '';
    const coach = (profileData && profileData.coach) ? JSON.parse(profileData.coach) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const views = (profileData && profileData.views) ? JSON.parse(profileData.views) : '';
    const academic_stats = (profileData && profileData.academic_stats) ? JSON.parse(profileData.academic_stats) : '';

    const hs_name = (school && school.hs_name) ? school.hs_name : '';
    const hs_city = (school && school.hs_city) ? school.hs_city : '';
    const hs_state = (school && school.hs_state) ? school.hs_state : '';

    const act_score = (academic_stats && academic_stats.act_score) ? academic_stats.act_score : '';
    const sat_score = (academic_stats && academic_stats.sat_score) ? academic_stats.sat_score : '';
    const gpa_score = (academic_stats && academic_stats.gpa_score) ? academic_stats.gpa_score : '';

    const coach_name = (coach && coach.coach_name) ? coach.coach_name : '';
    const coach_phone = (coach && coach.coach_phone) ? coach.coach_phone : '';

    const social_media_facebook = (social_media && social_media.facebook) ? social_media.facebook : '';
    const social_media_instagram = (social_media && social_media.instagram) ? social_media.instagram : '';
    const social_media_twitter = (social_media && social_media.twitter) ? social_media.twitter : '';
    const social_media_snapchat = (social_media && social_media.snapchat) ? social_media.snapchat : '';

    let stats = '';
    if (height_feet !== '' && height_inch !== '') {
        stats = `${height_feet}'${height_inch}"`;
    }
    if (weight) {
        stats += `  ${weight} lbs.`;
    }
    // const starsRating = (profileData.stars) ? (profileData.stars * 100) / 5 : 0;
    const cover_image = (profileData && profileData.cover_image) ? profileData.cover_image : '';

    let starsRating = 0;
    let profileScore = 0;
    if (profileData && (profileData.uprofile_style == 3 || profileData.uprofile_style == 4)) {
        starsRating = (profileData.wr_te_stars) ? (profileData.wr_te_stars * 100) / 5 : 0;
        profileScore = profileData.wr_te_final;
    } else {
        starsRating = (profileData.stars) ? (profileData.stars * 100) / 5 : 0;
        profileScore = profileData.uprofile_eval_final;
    }

    const location = useLocation();
    const pathname = location.pathname;
    const handleLoginNavigation = () => {
        navigate("/log-in", { state: { from: pathname } });
    };
    const cleanUrlString = (url) => {
        return url.replace(/\\'/g, "'").replace(/\\\\/g, '\\');
    };

    const hasYearData = Array.isArray(yearsField) && yearsField.some(item => typeof item.year === 'string' && item.year.trim() !== '');
    const cleanedAbout = (typeof about === 'string' ? about : String(about)).replace(/\\/g, '');

    // const parseHTML = (htmlString) => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(htmlString, 'text/html');
    //     return Array.from(doc.body.childNodes).map((node, index) => {
    //       if (node.nodeType === 3) {
    //         // Text node
    //         return node.textContent;
    //       } else if (node.nodeType === 1) {
    //         // Element node
    //         const tagName = node.tagName.toLowerCase();
    //         if (tagName === 'br') {
    //           // Handle <br> as a self-closing React element
    //           return <br key={index} />;
    //         }
    //         return React.createElement(
    //           tagName,
    //           { key: index },
    //           parseHTML(node.innerHTML) // Recursively parse child nodes
    //         );
    //       }
    //       return null;
    //     });
    // };
    return (
        <>
            <div className="profile-details-header-banner">
                <figure>
                    <img src={cover_image && cover_image[0] && cover_image[0].afile_physical_path ? `${baseUrl}/${cover_image[0].afile_physical_path}` : "/images/profile-detail-image.jpg"} alt="Cover" className="img-fluid" />
                </figure>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="qbh-player-list-box-detail">
                            <div className="row">
                                <div className="col-lg-3 col-sm-3">
                                    <div className="profile-detail-img">
                                        <figure>
                                            <img src={profile_image && profile_image[0] && profile_image[0].afile_physical_path ? `${baseUrl}/${profile_image[0].afile_physical_path}` : "/images/qbh-hit.jpg"} alt="Profile" className="img-fluid" />
                                        </figure>
                                    </div>

                                </div>

                                <div className="col-lg-3 col-sm-3">
                                    <div className="derek-laway">
                                        <h6>{profileData.uprofile_fname} {profileData.uprofile_lname}</h6>
                                        <p>{stats}</p>
                                        <p>{hs_name}</p>
                                        <p>{hs_city ? `${hs_city}, ` : ''}{hs_state}</p>
                                        <div>
                                            <p>Class of {profileData.uprofile_grad_class} <br />
                                                {getUserProfileStyles[profileData.uprofile_style]} </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-6 col-sm-6">
                                    <div className="top-ranking">
                                        <ul>
                                            {profileData.rank && (
                                                <li className="qbh">QBHL Ranking <span className="ranking-number">{profileData.rank}</span></li>
                                            )}
                                            <li>{getLabel('LBL_SCORE:')} <span className="ranking-number">{profileScore}</span></li>
                                            {starsRating ? (
                                                <li>
                                                    <div className="review__rating star-rating-menu">
                                                        <div className="icn" style={{ width: starsRating + '%' }}></div>
                                                    </div>
                                                </li>
                                            ) : (
                                                <>
                                                    {getLabel('LBL_Not_Yet_Rated')}
                                                </>
                                            )}
                                        </ul>
                                    </div>

                                    {views ? (
                                        <div className="views profile_view">{getLabel('LBL_PROFILE_VIEWS')}: <span className="profile-views">{views}</span></div>
                                    ) : (
                                        <></>
                                    )}
                                    {collegeStatus && (
                                        <div className="college--status">{getLabel('LBL_College_Status')}: <span className="profile-views">{collegeStatus}</span></div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <div className="social-media-profile">
                            <ul>
                                {social_media_twitter && (
                                    <li><a href={`https://www.twitter.com/${social_media_twitter}`} target="_blank" rel="noreferrer"><img src="/images/twiter.png" className="img-fluid" alt="twitter" />{social_media_twitter}</a></li>
                                )}
                                {social_media_instagram && (
                                    <li><a href={`https://www.instagram.com/${social_media_instagram}`} target="_blank" rel="noreferrer"><img src="/images/instagram.png" className="img-fluid" alt="instagram" />{social_media_instagram}</a></li>
                                )}
                                {social_media_facebook && (
                                    <li><a href={`https://www.facebook.com/${social_media_facebook}`} target="_blank" rel="noreferrer"><img src="/images/facebook.png" className="img-fluid" alt="facebook" />{social_media_facebook}</a></li>
                                )}
                                {social_media_snapchat && (
                                    <li><a href={`https://www.snapchat.com/add/${social_media_snapchat}`} target="_blank" rel="noreferrer"><img src="/images/snapchat-logo-24.png" className="img-fluid" alt="snpachat" />{social_media_snapchat}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                    {(profileData.uprofile_premium_overide == 1 || profileData.uprofile_is_premium == 1) && (
                        <div className="col-lg-6 col-sm-6">
                            <div className="premium-btn">
                                <p className="profile-premium"><img src="/images/trophy-star.png" className="img-fluid" alt="Premium" />  {getLabel('LBL_PREMIUM_MEMBER')}</p>
                            </div>
                        </div>
                    )}

                    {about && (
                        <div className="col-lg-12">
                            <blockquote>
                                {cleanedAbout}
                            </blockquote>
                        </div>
                    )}
                </div>
            </div>

            <div className="scouting-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            {(act_score || sat_score || gpa_score) && (
                                <div className="stats-box">
                                    <div className="inner-scouting-box">{getLabel('LBL_STATS')}</div>
                                    <div className="row">
                                        {gpa_score && (
                                            <div className="col-12 col-md-3 stat-score">
                                                <p className="base-stats">GPA: {gpa_score}</p>
                                            </div>
                                        )}
                                        {act_score && (
                                            <div className="col-12 col-md-3 stat-score">
                                                <p className="base-stats">ACT: {act_score}</p>
                                            </div>
                                        )}
                                        {sat_score && (
                                            <div className="col-12 col-md-3 stat-score">
                                                <p className="base-stats">SAT: {sat_score}</p>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            )}
                            {profileData.scouting_report && (profileData.uprofile_premium_overide == 1 || profileData.uprofile_is_premium == 1) && (
                                <div className="scouting-box">
                                    <div className="inner-scouting-box">{getLabel('LBL_SCOUTING')} <span className="report">{getLabel('LBL_REPORT')}</span></div>
                                    {/* <p>{profileData.scouting_report.replace(/\\/g, '')}</p> */}
                                    {/* <p>{parseHTML(profileData.scouting_report.replace(/\\/g, ''))}</p> */}
                                    <p dangerouslySetInnerHTML={renderHTML(profileData.scouting_report.replace(/\\/g, ''))} />
                                    
                                </div>
                            )}

                            <div className="sparq-score">
                                <div className="accordion" id="accordionExample">
                                    {(sparq_dash || sparq_bench || sparq_vertical || sparq_broad || sparq_cone) && (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className={`accordion-button ${expandedAccordion === 'sparq' ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    {getLabel('LBL_SPARQ_SCORES')}
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className={`accordion-collapse collapse ${expandedAccordion === 'sparq' ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>{getLabel('LBL_40_Yard_Dash:')} {sparq_dash}</li>
                                                        <li>{getLabel('LBL_Bench_Press:')} {sparq_bench}</li>
                                                        <li>{getLabel('LBL_Vertical_Jump:')} {sparq_vertical}</li>
                                                        <li>{getLabel('LBL_Broad_Jump:')} {sparq_broad}</li>
                                                        <li>{getLabel('LBL_3-Cone_Drill:')} {sparq_cone}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(hasYearData) && (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className={`accordion-button ${expandedAccordion === 'year' ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseYear" aria-expanded="true" aria-controls="collapseYear">
                                                    {getLabel('LBL_HIGH_SCHOOL_STATS')}
                                                </button>
                                            </h2>
                                            <div id="collapseYear" className={`accordion-collapse collapse ${expandedAccordion === 'year' ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {yearsField && yearsField.map((elem, index) => {
                                                        if (elem.year) {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{elem.year}</th>
                                                                                <th>{getLabel('LBL_Games_Played')}: {elem.games}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                {elem.complete && (
                                                                                    <td>{getLabel('LBL_Completions')}: {elem.complete}</td>
                                                                                )}
                                                                                {elem.attempt && (
                                                                                    <td>{getLabel('LBL_Passing_Attempts')}: {elem.attempt}</td>
                                                                                )}
                                                                                {elem.yard && (
                                                                                    <td>{getLabel('LBL_Passing_Yards')}: {elem.yard}</td>
                                                                                )}
                                                                            </tr>
                                                                            <tr>
                                                                                {elem.pass_td && (
                                                                                    <td>{getLabel('LBL_Passing_TDs')}: {elem.pass_td}</td>
                                                                                )}
                                                                                {elem.rush_yd && (
                                                                                    <td>{getLabel('LBL_Rushing_Yards')}: {elem.rush_yd}</td>
                                                                                )}
                                                                                {elem.rush_td && (
                                                                                    <td>{getLabel('LBL_Rushing_TDs')}: {elem.rush_td}</td>
                                                                                )}
                                                                            </tr>
                                                                            <tr>
                                                                                {elem.wr_receptions && (
                                                                                    <td>{getLabel('LBL_Receptions(WR)')}: {elem.wr_receptions}</td>
                                                                                )}
                                                                                {elem.wr_total_yds && (
                                                                                    <td>{getLabel('LBL_Total_Yds(WR)')}: {elem.wr_total_yds}</td>
                                                                                )}
                                                                                {elem.wr_yards_games && (
                                                                                    <td>{getLabel('LBL_Yards/game(WR)')}: {elem.wr_yards_games}</td>
                                                                                )}
                                                                            </tr>
                                                                            {elem.wr_tds && (
                                                                                <tr>
                                                                                    <td>{getLabel('LBL_TDs(WR)')}: {elem.wr_tds}</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {profileData.offers && profileData.offers.length > 0 && (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className={`accordion-button ${expandedAccordion === 'offers' ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    {getLabel('LBL_COLLEGE_OFFERS_/_COMMITS')}
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className={`accordion-collapse collapse ${expandedAccordion === 'offers' ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{getLabel('LBL_Commit')}</th>
                                                                    <th>{getLabel('LBL_School')}</th>
                                                                    <th>{getLabel('LBL_Date')}</th>
                                                                    <th>{getLabel('LBL_Type')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {profileData.offers && profileData.offers.map((elem, index) => {
                                                                    if (elem.poffer_date || elem.poffer_college) {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    {elem.poffer_commit === 1 && (
                                                                                        <i className="bx bx-check"></i>
                                                                                    )}
                                                                                </td>
                                                                                <td>{elem.poffer_college}</td>
                                                                                <td>
                                                                                    {elem.poffer_date ? formatCalendarDate(elem.poffer_date) : null}
                                                                                </td>
                                                                                <td>{getOfferTypes[elem.poffer_type]}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {training && training.length > 0 && (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className={`accordion-button ${expandedAccordion === 'training' ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTraining" aria-expanded="false" aria-controls="collapseTraining">
                                                    {getLabel('LBL_TRAINING')}
                                                </button>
                                            </h2>
                                            <div id="collapseTraining" className={`accordion-collapse collapse ${expandedAccordion === 'training' ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{getLabel('LBL_Name/Type')}</th>
                                                                    <th>{getLabel('LBL_Date')}</th>
                                                                    <th>{getLabel('LBL_Location')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {training && training.map((elem, index) => {
                                                                    if (elem.training_name || elem.training_date || elem.training_location) {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{elem.training_name}</td>
                                                                                <td>{formatCalendarDate(elem.training_date)}</td>
                                                                                <td>{elem.training_location}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {camps && camps.length > 0 && (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className={`accordion-button ${expandedAccordion === 'camps' ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseCamps" aria-expanded="false" aria-controls="collapseCamps">
                                                    {getLabel('LBL_CAMPS')}
                                                </button>
                                            </h2>
                                            <div id="collapseCamps" className={`accordion-collapse collapse ${expandedAccordion === 'camps' ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{getLabel('LBL_Name/Type')}</th>
                                                                    <th>{getLabel('LBL_Date')}</th>
                                                                    <th>{getLabel('LBL_Location')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {camps && camps.map((elem, index) => {
                                                                    if (elem.event_name || elem.event_date || elem.event_location) {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{elem.event_name}</td>
                                                                                <td>{formatCalendarDate(elem.event_date)}</td>
                                                                                <td>{elem.event_location}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {awards && awards.length > 0 && (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className={`accordion-button ${expandedAccordion === 'awards' ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    {getLabel('LBL_HONORS_AND_AWARDS')}
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className={`accordion-collapse collapse ${expandedAccordion === 'awards' ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{getLabel('LBL_Name')}</th>
                                                                    <th>{getLabel('LBL_Date')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {awards && awards.map((elem, index) => {
                                                                    if (elem.award_name || elem.award_date) {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{elem.award_name}</td>
                                                                                <td>{formatCalendarDate(elem.award_date)}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            {profileScore ? (
                                <>
                                    <div className="evalution-scores">
                                        <div className="inner-scouting-box">{getLabel('LBL_EVALUATION')} <span className="report">{getLabel('LBL_SCORES')}</span></div>
                                        <div className="socre-list">
                                            {(profileData.uprofile_premium_overide == 1 || profileData.uprofile_is_premium == 1) && (
                                                <>
                                                    {profileData.uprofile_style == 3 || profileData.uprofile_style == 4 ? (
                                                        <ul>
                                                            <li>{getLabel('LBL_Size:')} {profileData.wr_te_size} <span>{getLabel('LBL_Speed:')} {profileData.wr_te_speed}</span></li>
                                                            <li>{getLabel('LBL_Release:')} {profileData.wr_te_release} <span>{getLabel('LBL_Catching:')} {profileData.wr_te_catching}</span></li>
                                                            <li>{getLabel('LBL_Catching_in_Traffic:')} {profileData.wr_te_catching_traffic} <span>{getLabel('LBL_Route_Running/Short:')} {profileData.wr_te_route_short}</span></li>
                                                            <li>{getLabel('LBL_Route_Running/Med:')} {profileData.wr_te_route_med} <span>{getLabel('LBL_Route_Running/Deep:')} {profileData.wr_te_route_deep}</span></li>
                                                            <li>{getLabel('LBL_Blocking:')} {profileData.wr_te_blocking} <span>{getLabel('LBL_Elusiveness:')} {profileData.wr_te_elusiveness}</span></li>
                                                        </ul>
                                                    ) : (
                                                        <ul>
                                                            <li>{getLabel('LBL_Size:')} {profileData.size} <span>{getLabel('LBL_Footwork:')} {profileData.footwork}</span></li>
                                                            <li>{getLabel('LBL_Accuracy:')} {profileData.accuracy} <span>{getLabel('LBL_Poise:')} {profileData.poise}</span></li>
                                                            <li>{getLabel('LBL_Arm_Strength:')} {profileData.arm_strength} <span>{getLabel('LBL_Pocket_Presence:')} {profileData.pocket_presence}</span></li>
                                                            <li>{getLabel('LBL_Release:')} {profileData.release} <span>{getLabel('LBL_Decision_Making:')} {profileData.decision_making}</span></li>
                                                            <li>{getLabel('LBL_Throw_on_Run:')} {profileData.throw_on_the_run} <span>{getLabel('LBL_Touch:')} {profileData.touch}</span></li>
                                                        </ul>
                                                    )}
                                                </>
                                            )}
                                            <div className="scorebtn">
                                                <a className="btn btn-primary ">{getLabel('LBL_Score:')} {profileScore}</a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {isLoggedIn() === true && (profileData.uprofile_email || profileData.uprofile_address || profileData.uprofile_phone_no) && (
                                <div className="evalution-scores-contact">
                                    <div className="inner-scouting-box">{getLabel('LBL_CONTACT:')}</div>
                                    <div className="address-list contact-info">
                                        <ul>
                                            {profileData.uprofile_email && (
                                                <li><i className='bx bx-envelope-open' style={{ color: "#ab6601" }} ></i><a href={`mailto:${profileData.uprofile_email}`}>{profileData.uprofile_email}</a></li>
                                            )}
                                            {profileData.uprofile_phone_no && (
                                                <li><i className='bx bx-phone-call' style={{ color: "#ab6601" }} ></i><a href={`tel:${profileData.uprofile_phone_no}`}>{profileData.uprofile_phone_no}</a></li>
                                            )}
                                            {profileData.uprofile_address && (
                                                <li><i className='bx bx-map' style={{ color: "#ab6601" }} ></i>{profileData.uprofile_address} {profileData.uprofile_city}, {profileData.uprofile_state_code} {profileData.uprofile_zip}</li>
                                            )}
                                            {coach_name && (
                                                <li><i className='bx bx-user' style={{ color: "#ab6601" }} ></i> {coach_name} <br /> <span className="coach_phone">{coach_phone}</span></li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <div className="container profiles-highlight">
                <div className="row">
                    {isLoggedIn() === false && (
                        <div className="click-here">
                            <p>Coach? <a onClick={handleLoginNavigation} className="click-btn">Click Here </a> to view all Profile Information</p>
                        </div>
                    )}
                    {video_info && video_info.length > 0 && (
                        <>
                            <div className="col-lg-12 twitter-qbh">
                                <div className="bottom-border"></div>
                                <h2>{getLabel('LBL_HIGHLIGHTS')}</h2>
                            </div>

                            <div className="custom-tab">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {(profileData.uprofile_premium_overide == 1 || profileData.uprofile_is_premium == 1)
                                        ? video_info.map((elem, index) => (
                                            <li className="nav-item" role="presentation" key={index}>
                                                <a className={`nav-link ${index == 0 ? 'active' : ''}`} id={`video${index + 1}-tab`} data-bs-toggle="tab" data-bs-target={`#video${index + 1}`}
                                                    type="button" role="tab" aria-controls={`video${index + 1}`} aria-selected={`${index == 0 ? 'true' : 'false'}`}>{getLabel('LBL_Highlight_Video')} {index + 1}</a>
                                            </li>
                                        ))
                                        : video_info.slice(0, 2).map((elem, index) => (
                                            <li className="nav-item" role="presentation" key={index}>
                                                <a className={`nav-link ${index == 0 ? 'active' : ''}`} id={`video${index + 1}-tab`} data-bs-toggle="tab" data-bs-target={`#video${index + 1}`}
                                                    type="button" role="tab" aria-controls={`video${index + 1}`} aria-selected={`${index == 0 ? 'true' : 'false'}`}>{getLabel('LBL_Highlight_Video')} {index + 1}</a>
                                            </li>
                                        ))
                                    }
                                </ul>

                                <div className="inner-text-tab">
                                    <div className="tab-content" id="myTabContent">
                                        {(profileData.uprofile_premium_overide == 1 || profileData.uprofile_is_premium == 1)
                                            ? video_info.map((elem, index) => {
                                                const elemStr = String(cleanUrlString(elem.url)).trim();
                                                const isHudl = elemStr.includes('hudl.com/video');
                                                const isIframe = elemStr.startsWith('<iframe');
                                                const iframeSrc = isHudl ? elemStr : convertYouTubeURL(elemStr);
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`tab-pane fade ${index == 0 ? 'active show' : ''}`}
                                                        id={`video${index + 1}`}
                                                        role={`tabpane${index + 1}`}
                                                        aria-labelledby={`video${index + 1}-tab`}
                                                    >
                                                        {isIframe ? (
                                                            <div className="iframe--video" dangerouslySetInnerHTML={{ __html: elemStr }} />
                                                        ) : (
                                                            <iframe
                                                                src={iframeSrc}
                                                                allowFullScreen
                                                                width="100%"
                                                                height="480"
                                                                style={{ width: '100%', height: '480px' }}
                                                                frameBorder="0"
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })
                                            : video_info.slice(0, 2).map((elem, index) => {
                                                const elemStr = String(cleanUrlString(elem.url)).trim();
                                                const isHudl = elemStr.includes('hudl.com/video');
                                                const isIframe = elemStr.startsWith('<iframe');
                                                const iframeSrc = isHudl ? elemStr : convertYouTubeURL(elemStr);
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`tab-pane fade ${index == 0 ? 'active show' : ''}`}
                                                        id={`video${index + 1}`}
                                                        role={`tabpane${index + 1}`}
                                                        aria-labelledby={`video${index + 1}-tab`}
                                                    >
                                                        {isIframe ? (
                                                            <div className="iframe--video" dangerouslySetInnerHTML={{ __html: elemStr }} />
                                                        ) : (
                                                            <iframe
                                                                src={iframeSrc}
                                                                allowFullScreen
                                                                width="100%"
                                                                height="480"
                                                                style={{ width: '100%', height: '480px' }}
                                                                frameBorder="0"
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    )
}

export default ProfileDetail
